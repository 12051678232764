import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuthQuery } from "../TokenQuery"
import { integrateCreateConsumption, integrateDeleteConsumption } from "./ConsumptionIntegrations"
import { API_BASEURL } from "../../Api"
import { Consumption } from "../../EnergyMeter"

export const useDeleteConsumptionMutation = () => {
    const { msToken } = useAuthQuery()
    const client = useQueryClient()
    
    const deleteConsumptionMutation = useMutation({
        mutationFn: deleteConsumption(msToken),
        onSuccess: integrateDeleteConsumption(client)
    })

    return { deleteConsumption: deleteConsumptionMutation }
}

export const useCreateConsumptionMutation = () => {
    const { msToken } = useAuthQuery()
    const client = useQueryClient()

    const createConsumptionMutation = useMutation({
        mutationFn: createConsumption(msToken),
        onSuccess: integrateCreateConsumption(client)
    })

    return { createConsumption: createConsumptionMutation }
}

const deleteConsumption = (accessToken?: string) => async (consumptionId: string) => {

    await fetch(API_BASEURL + `/api/delete_energy_meter_consumption.php`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken!
        },
        body: JSON.stringify({ id: consumptionId }),
    })

    return consumptionId
}

const createConsumption = (accessToken?: string) => async ({ meterId, value, date }: {meterId:string, value:string, date:string}) => {
    const response = await fetch(API_BASEURL + '/api/add_consumption.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken!
        },
        body: JSON.stringify({ meterId, value, date }),
    });
    const resp = await response.json()
    return resp as Consumption
}