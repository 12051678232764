import { useQuery } from "@tanstack/react-query"
import { API_BASEURL } from "../../Api"
import { Consumption } from "../../EnergyMeter"
import { useAuthQuery } from "../TokenQuery"

export const consumptionsKey = "consumptions"

export const useConsumptionQuery = () => {
    const { isAuthenticated, msToken } = useAuthQuery()

    const { data } = useQuery({
        queryKey: [consumptionsKey],
        queryFn: getConsumptions(msToken),
        enabled: isAuthenticated,
        staleTime: 5 * 60 * 1000
    })

    const map = new Map<string, Consumption[]>()
    data?.forEach(d => {
        if(!map.has(d.meter_id)){
            map.set(d.meter_id, [])
        }
        map.get(d.meter_id)!.push(d)
    })

    return { consumptionsByMeterId: map }
}

const getConsumptions = (accessToken?: string) => async () => {
    const response = await fetch(API_BASEURL + '/api/get_consumptions.php', {
        headers: {
            'Authorization': accessToken!
        }
    })
    return await response.json() as Consumption[];
}