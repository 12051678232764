import React, { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useCreateEnergyMeterMutator } from '../store/energyMeters/EnergyMeterMutators';

interface EnergyMeterFormProps { onCreation: () => void }

export const EnergyMeterCreationForm: FC<EnergyMeterFormProps> = ({ onCreation }) => {
    const [meterId, setMeterId] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [unit, setUnit] = useState('');

    const { createEnergyMeter } = useCreateEnergyMeterMutator()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        await createEnergyMeter.mutateAsync({
            id: meterId,
            display_name: displayName,
            unit: unit
        })
        onCreation()

        setMeterId('');
        setDisplayName('');
        setUnit('');
    };

    return (
        <form onSubmit={handleSubmit} style={{gap:5, display:'flex', alignItems:'center', flexDirection:'column'}}>
            <input required type="text" value={displayName} onChange={(e) => setDisplayName(e.target.value)} placeholder="Anzeigename" />
            <input required type="text" value={meterId} onChange={(e) => setMeterId(e.target.value)} placeholder="Zählernummer" />
            <input required type="text" value={unit} onChange={(e) => setUnit(e.target.value)} placeholder="Einheit" />
            <Button type="submit">Erstellen</Button>
        </form>
    );
};