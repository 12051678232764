import React, { FC, useEffect, useRef, useState } from 'react';
import { Consumption, ConsumptionDelta, EnergyMeter } from '../EnergyMeter';
import { Table } from 'react-bootstrap';
import { Chart } from 'chart.js/auto';
import interpolate from 'color-interpolate';
import { useConsumptionQuery } from '../store/consumptions/ConsumptionQuery';
import { useDeleteConsumptionMutation } from '../store/consumptions/ConsumptionMutations';

interface ConsumptionOverviewProps { meter: EnergyMeter }

function calculateAverageConsumptionPerDay(data: Consumption[]): ConsumptionDelta[] {
    if (data.length < 2) {
        return []
    }

    var deltas: ConsumptionDelta[] = []

    data.sort((a, b) => new Date(a.date.date).getTime() - new Date(b.date.date).getTime());

    for (let i = 1; i < data.length; i++) {
        const currentDate = new Date(data[i].date.date);
        const prevDate = new Date(data[i - 1].date.date);

        const daysDifference = (currentDate.getTime() - prevDate.getTime()) / (24 * 60 * 60 * 1000);
        const consumptionDifference = data[i].value - data[i - 1].value;
        const averageConsumptionPerDay = consumptionDifference / daysDifference;

        deltas.push({
            averageConsumptionPerDay: averageConsumptionPerDay,
            consumptionBetween: consumptionDifference,
            daysBetween: daysDifference,
            endDate: currentDate,
            startDate: prevDate
        })
    }

    return deltas
}

export const ConsumptionOverview: FC<ConsumptionOverviewProps> = ({ meter }) => {

    const { consumptionsByMeterId } = useConsumptionQuery()
    const consumptions = consumptionsByMeterId?.get(meter.id) ?? []
    
    const sorted = [...consumptions].sort((a, b) => new Date(b.date.date).getTime() - new Date(a.date.date).getTime());
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstanceRef = useRef<Chart | null>(null);
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const deltas = calculateAverageConsumptionPerDay(consumptions)
    const sortedDeltas = [...deltas].sort((a, b) => a.averageConsumptionPerDay - b.averageConsumptionPerDay);
    const min = Math.min(...sortedDeltas.map(d => d.averageConsumptionPerDay));
    let max = Math.max(...sortedDeltas.map(d => d.averageConsumptionPerDay));
    if(max === min) max = min + 1;
    const shownDeltas = deltas.filter(d => d.endDate.getFullYear() === year)

    const getBgc = (value:number) => interpolateColor((value-min)/(max-min))

    const { deleteConsumption } = useDeleteConsumptionMutation()

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'scatter',
                    data: {
                        labels: shownDeltas.map(d => d.endDate.toLocaleDateString()),
                        datasets: [{
                            label: `Avg dy/dt`,
                            data: shownDeltas.map(d => { return { x: d.endDate.getTime(), y: d.averageConsumptionPerDay } }),
                            borderColor: '#0d6efd',
                            borderWidth: 1,
                            pointRadius: 1,
                            fill: false,
                        }],
                    },
                    options: {
                        scales: {
                            x: {
                                ticks: {
                                    callback: (value) => new Date(value).toLocaleDateString()
                                },
                                min: new Date(year, 0, 1).getTime(),
                                max: new Date(year + 1, 0, 1).getTime(),
                            }
                        }
                    }
                });
            }
        }

        return () => chartInstanceRef?.current?.destroy()
    }, [deltas, shownDeltas, year]);

    return (
        <div style={{marginBlock:20}}>
            <label>Year:</label>
            <input type="number" id="yearInput" name="year" min="1900" max="2100" value={year} onChange={(e) => setYear(+e.target.value)}/>
            <canvas ref={chartRef} width={400} height={200} style={{ maxHeight: 400 }}></canvas>
            <Table style={{marginTop:20}}>
                <tbody>
                    {sorted.map((c,i) => <tr key={c.id}
                        onContextMenu={async (e) => {
                            e.preventDefault();
                            try {
                                if (prompt(`Willst du den Eintrag vom ${new Date(c.date.date).toLocaleDateString()} wirklich löschen? Dann schreibe 'ja'`)?.toLowerCase() !== "ja") {
                                    return;
                                }
                                await deleteConsumption.mutateAsync(c.id)
                            } catch (error) {
                                console.error('Failed to delete the energy meter:', error);
                            }
                        }}
                    >
                        <td>{new Date(c.date.date).toLocaleDateString()}</td>
                        <td>{c.value.toFixed(0)}{meter.unit}</td>
                        {deltas.length-i-1 >= 0 ? 
                            <td style={{backgroundColor:getBgc(deltas[deltas.length-i-1].averageConsumptionPerDay)}}>
                                {i !== deltas.length ? deltas[deltas.length-i-1].averageConsumptionPerDay.toFixed(2) + meter.unit + '/Tag' : ''}
                            </td> : 
                            <td></td>
                        }
                        
                    </tr>)}
                    <tr><td colSpan={3}>Zum löschen gedrückt halten.</td></tr>
                </tbody>
            </Table>
        </div>
    );
};


function interpolateColor(value:any) {
    if(Number.isNaN(value)){
        return 'unset'
    }

    if(value === null || value === undefined){
        console.error(value, 'value is null')
        return undefined
    }

    let colormap = interpolate(['green', 'yellow', 'red']);
    const v = Math.min(1, Math.max(0, value))
    return colormap(v);
  }