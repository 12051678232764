import './App.css';
import { FC } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import EnergyMeterList from './EnergyMeterList';
import LoginForm from './LoginForm';

const msalConfig = {
  auth: {
    clientId: '6316e349-a1df-4e2f-a426-ab9472fee8e6',
    authority: 'https://login.microsoftonline.com/bfa98759-cf17-4f76-a45b-7f0474af636f',
    redirectUri: '/'
  }
};



export const App: FC = () => {
  
  const msalInstance = new PublicClientApplication(msalConfig);
  
  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <EnergyMeterList />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <LoginForm />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </div>
  );
}