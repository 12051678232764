import { useQuery } from "@tanstack/react-query";
import { API_BASEURL } from "../../Api";
import { EnergyMeter } from "../../EnergyMeter";
import { useAuthQuery } from "../TokenQuery";

export const energyMetersKey = "EnergyMeters"
export const selectedEnergyMetersKey = "SelectedEnergyMeters"

export const useEnergyMeterQuery = () => {

    const { isAuthenticated, msToken } = useAuthQuery()

    const { data: energyMeters } = useQuery({
        queryKey: [energyMetersKey],
        queryFn: getEnergyMeters(msToken!),
        enabled: isAuthenticated,
        staleTime: 5 * 60 * 1000
    })

    const { data: selectedMeterId } = useQuery<string>({
        queryKey: [selectedEnergyMetersKey]
    })

    const selectedMeter = () => {
        if (!energyMeters) return undefined;
        let matching = energyMeters?.filter(m => m.id === selectedMeterId)
        if (matching.length !== 1) return undefined;
        return matching[0];
    }

    return { energyMeters, selectedMeterId, selectedMeter: selectedMeter() }
}

const getEnergyMeters = (msToken: string) => async () => {

    const response = await fetch(API_BASEURL + '/api/list_energy_meters.php', {
        headers: {
            'Authorization': msToken
        }
    })
    const data = await response.json()

    return data as EnergyMeter[]
}