import { FC, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { useEnergyMeterQuery } from '../store/energyMeters/EnergyMeterQueries';
import { useDeleteEnergyMeterMutator, useSetSelectedEnergyMeterMutator } from '../store/energyMeters/EnergyMeterMutators';
import { useAuthQuery } from '../store/TokenQuery';
import ConsumptionCreationForm from './ConsumptionCreationForm';
import { ConsumptionOverview } from './ConsumptionOverview';
import { EnergyMeterCreationForm } from './EnergyMeterCreationForm';


const EnergyMeterList: FC = () => {

  const { energyMeters, selectedMeterId, selectedMeter } = useEnergyMeterQuery()
  const { isAuthenticated } = useAuthQuery()

  const { deleteEnergyMeter } = useDeleteEnergyMeterMutator()
  const { setSelectedEnergyMeter } = useSetSelectedEnergyMeterMutator()
  const [meterCreationHidden, setMeterCreationHidden] = useState<boolean>(true);

  

  const handleDelete = async (id: string) => {
    try {
      if (prompt("Willst du den Zähler wirklich löschen? Dann schreibe 'ja'")?.toLowerCase() !== "ja") {
        return;
      }
      await deleteEnergyMeter.mutateAsync(id)
    } catch (error) {
      console.error('Failed to delete the energy meter:', error);
    }
  };

  if (!isAuthenticated) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Logging in...</span>
      </Spinner>
    );
  }

  return (
    <div>
      <h1>Zähler</h1>
      <Table bordered>
        <tbody>
          {
            energyMeters ?
              energyMeters.map((meter) => (
                <tr className={selectedMeterId && selectedMeterId === meter.id ? 'table-active' : ''} key={meter.id} onClick={() => setSelectedEnergyMeter.mutate(meter.id)}>
                  <td>{meter.display_name}</td>
                  <td>{meter.id}</td>
                  <td><Button onClick={() => handleDelete(meter.id)}>-</Button></td>
                </tr>
              )) :
              <tr><td><Spinner animation="border" /></td></tr>
          }
        </tbody>
      </Table>
      {selectedMeterId != null && selectedMeter && <ConsumptionCreationForm meterId={selectedMeterId} />}
      {selectedMeter != null && <ConsumptionOverview meter={selectedMeter!} />}
      {energyMeters != null && !meterCreationHidden ?
        <>
          <Button style={{ marginBlock: 10 }} onClick={() => setMeterCreationHidden(true)}>Verstecken</Button>
          <EnergyMeterCreationForm onCreation={() => setMeterCreationHidden(true)} />
        </> :
        <Button onClick={() => setMeterCreationHidden(false)}>+</Button>}
    </div>
  );
};

export default EnergyMeterList;