import { useQuery } from "@tanstack/react-query";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export const msTokenKey = "msToken"
export const msNameKey = "msName"
export const userTokenKey = "userToken"

const scopes = ["api://6316e349-a1df-4e2f-a426-ab9472fee8e6/Usage"]

export const useAuthQuery = () => {
    const { instance, accounts } = useMsal()
    const msalIsAuthenticated = useIsAuthenticated()

    const { data: msName } = useQuery({
        queryKey: [msNameKey],
        queryFn: () => {
            return accounts[0].name
        },
        staleTime: 0,
        enabled: msalIsAuthenticated
    })

    const { data: msToken } = useQuery({
        queryKey: [msTokenKey],
        queryFn: async () => {
            const account = accounts[0]
            const response = await instance.acquireTokenSilent({
                scopes: scopes,
                account: account,
            })
            return response.accessToken
        },
        staleTime: 0,
        enabled: msalIsAuthenticated
    })

    
    return { msToken, msName, isAuthenticated: msalIsAuthenticated && msToken !== undefined }
}