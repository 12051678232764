import React, { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useCreateConsumptionMutation } from '../store/consumptions/ConsumptionMutations';

interface ConsumptionCreationFormProps {meterId: string}

const ConsumptionCreationForm: FC<ConsumptionCreationFormProps> = ({meterId}) => {
  
    const { createConsumption } = useCreateConsumptionMutation()
    const [value, setValue] = useState('');
    const [date, setDate] = useState(new Date().toISOString().slice(0, 16));

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await createConsumption.mutateAsync({meterId, value, date})
        setValue('')
    };

    return (
        <form onSubmit={handleSubmit} style={{gap:5, display:'flex', alignItems:'center', flexDirection:'column'}}>
            <input required style={{width:'100%'}} type="number" value={value} onChange={(e) => setValue(e.target.value)} placeholder="Zählerstand" />
            <input required style={{width:'100%'}} type="datetime-local" value={date} onChange={(e) => setDate(e.target.value)} placeholder="Datum" />
            <Button style={{width:'100%'}} type="submit">Hinzufügen</Button>
        </form>
    );
};

export default ConsumptionCreationForm;