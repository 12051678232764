import { useMutation, useQueryClient } from "@tanstack/react-query"
import { API_BASEURL } from "../../Api";
import { useAuthQuery } from "../TokenQuery";
import { integrateCreateEnergyMeter, integrateDeleteEnergyMeter } from "./EnergyMeterIntegrations";
import { EnergyMeter } from "../../EnergyMeter";
import { selectedEnergyMetersKey } from "./EnergyMeterQueries";

export const useCreateEnergyMeterMutator = () => {
    const { msToken } = useAuthQuery()
    const client = useQueryClient()

    const createEnergyMeterMutator = useMutation({
        mutationFn: createEnergyMeter(msToken),
        onSuccess: integrateCreateEnergyMeter(client)
    })

    return { createEnergyMeter: createEnergyMeterMutator }
}

export const useDeleteEnergyMeterMutator = () => {
    const { msToken } = useAuthQuery()
    const client = useQueryClient()

    const deleteEnergyMeterMutator = useMutation({
        mutationFn: deleteEnergyMeter(msToken),
        onSuccess: integrateDeleteEnergyMeter(client)
    })

    return { deleteEnergyMeter: deleteEnergyMeterMutator }
}

export const useSetSelectedEnergyMeterMutator = () => {
    const client = useQueryClient()

    const setSelectedEnergyMeterMutator = useMutation({
        mutationFn: async (selectedId:string) => client.setQueryData([selectedEnergyMetersKey], ()  => selectedId),
    })

    return { setSelectedEnergyMeter: setSelectedEnergyMeterMutator }
}

const createEnergyMeter = (accessToken?: string) => async (newEnergyMeter: EnergyMeter) => {
    const response = await fetch(API_BASEURL + '/api/create_energy_meter.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken!
        },
        body: JSON.stringify({ meterId: newEnergyMeter.id, displayName: newEnergyMeter.display_name, unit: newEnergyMeter.unit }),
    });
    const data = await response.json()

    return data as EnergyMeter
}

const deleteEnergyMeter = (accessToken?: string) => async (id:string) => {
    await fetch(API_BASEURL + `/api/delete_energy_meter.php`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken!
        },
        body: JSON.stringify({ id }),
    })
    return id
}

