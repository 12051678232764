import { useMsal } from '@azure/msal-react';
import React, { FC } from 'react';
import { Button } from 'react-bootstrap';

interface LoginFormProps {}

const LoginForm: FC<LoginFormProps> = () => {
  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <>
      <Button onClick={initializeSignIn}>Login mit Microsoft</Button>
    </>
  );
};

export default LoginForm;