import { QueryClient } from "@tanstack/react-query"
import { EnergyMeter } from "../../EnergyMeter"
import { energyMetersKey, selectedEnergyMetersKey } from "./EnergyMeterQueries"


export const integrateDeleteEnergyMeter = (client: QueryClient) => (deletedId: string) => {
    client.setQueryData([energyMetersKey], (oldData: EnergyMeter[]) => oldData.filter(m => m.id !== deletedId))
    const selectedMeterId = client.getQueryData<string>([selectedEnergyMetersKey])
    if(selectedMeterId === deletedId){
        client.invalidateQueries({ queryKey: [selectedEnergyMetersKey] })
    }
}

export const integrateCreateEnergyMeter = (client: QueryClient) => (newMeter: EnergyMeter) => {
    client.setQueryData([energyMetersKey], (oldData: EnergyMeter[]) => [...oldData, newMeter])
    client.setQueryData([selectedEnergyMetersKey], (oldSelectedId: string)  => newMeter.id)
}