import { QueryClient } from "@tanstack/react-query"
import { Consumption } from "../../EnergyMeter"
import { consumptionsKey } from "./ConsumptionQuery"

export const integrateDeleteConsumption = (client: QueryClient) => (deletedId: string) => {
    client.setQueryData([consumptionsKey], (oldData: Consumption[]) => oldData.filter(c => c.id !== deletedId))
}

export const integrateCreateConsumption = (client: QueryClient) => (createdConsumption: Consumption) => {
    client.setQueryData([consumptionsKey], (oldData: Consumption[]) => [...oldData, createdConsumption])
}